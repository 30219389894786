import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
              Hi, I'm <span className="purple">David. </span>
            <br/>
            <br/>
            I'm a Full Stack Software Developer.
            My favorite genre of music to listen to while programming is EDM; Specifically dubstep. 
            While it's not a very popular choice for most, It's what gets me hyper-focused. 
            I am absolutely passionate about building Gaming PC's. Feel free to reach out and ask me
            anything about them.
            <br />
            <br/>
            I have 2 border collies -Duke and Daisy- Who are siblings. They are obsessed with the w-word (walk), and go absolutely
            insane when they hear it mentioned.
            <br />
            <br />
              Some of my Hobbies include:
              <br/>
              
              <b className="purple"> 🎣 Fishing </b>
              <br/>
              <b className="purple"> 🏌️‍♂️ Golfing </b>
              <br/>
              <b className="purple"> 🛹 Skateboarding  </b>

            </p>

          <p style={{ color: "#7ea6de" }}>
            "The only constant in life is change".{" "}
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
