import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <div
    style={{textShadow: "0 0 20px #20a5f7"}}>
    <Typewriter
    
      options={{
        strings: [
          "Software Developer",
          "Freelancer",
          "Technologist",
          "Lover, Not a Fighter",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
    </div>
  );
}

export default Type;
