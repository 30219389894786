import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/David.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              My programming journey began in 2019 in Salt Lake City. There, I learned the basics basics: <i>
                <b className="purple"> Node, React.js, MongoDB, Express </b> and good ol' <b className="purple">HTML/CSS. </b>
              </i> 
              <br />
              <br />
              I started my first job in 2019 as a web developer for a small startup in Albuquerque. Later, I was hired in 2021 as a Remote Programmer Analyst 
              at SPARQ. I'm currently living in Salt Lake City, UT. My goal is to take my career to the next level
              by applying skills/experience in an environment that aligns with my passions. 
              <br />
              
              <br />
              
              I graduated from a coding bootcamp in Salt Lake City called "V-School" back in 2019. I now have almost 5 years of experience working on teams of 
              devs, QA, Scrum Masters, Clients and others involved in the application lifecycle. Professionally, I've worked on finance, inventory, e-commerce, admin platforms, and many other 
              types of applications. 
              </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" style={{borderRadius: "50%", maxHeight: "300px"}}/>
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>WHERE TO FIND ME</h1>
            <p>
              Feel free to <span className="purple">connect </span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/davidnielsen215"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/david-nielsen-a02702188/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/david.nielsen/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
