import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import map from "../../Assets/Projects/google-maps.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent <strong className="purple">Apps </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently. 
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={map}
              isBlog={false}
              title="Weather Forecast"
              description="Google map API that pulls weather forecast information with a click on the map."
              ghLink="https://github.com/davidnielsen215/Google-Forecast"
              demoLink="https://dn-googlemap.surge.sh//"
              />
          </Col>
              <i style={{color: 'white'}}>Currently in process of deploying more soon, Most of my time was spent programming proprietary company apps... </i>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Bits-0f-C0de"
              description="My personal blog page build with Next.js and Tailwind Css which takes the content from makdown files and renders it using Next.js. Supports dark mode and easy to write blogs using markdown."
              ghLink=""
              demoLink=""
            />
          </Col>

          */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
